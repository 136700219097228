import React from "react"
import {
  Button,
  ButtonProps,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/core"
import Body from "../typography/Body"
import Heading from "../typography/Heading"
import Paragraph from "../typography/Paragraph"
import ModalCloseButton from "../Buttons/ModalCloseButton"

export type ModalButtonProps = Omit<ButtonProps, "children"> & {
  iconName: string
  text: string
  modalBody: string
  title: string
}

export default function ModalButton({
  iconName,
  text,
  modalBody,
  title,
  color = "dawn",
  borderColor = "dividerLine",
  ...props
}: ModalButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button
        h="3.5rem"
        py="1.125rem"
        justifyContent="center"
        border="1px solid"
        borderColor={borderColor}
        borderRadius="0.5rem"
        alignItems="center"
        variant="outline"
        onClick={onOpen}
        {...props}
      >
        <Icon name={iconName} size="1rem" color={color} />
        <Body color={color} ml="0.75rem">
          {text}
        </Body>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
          width={["100%", null, null, null, "60vw"]}
          maxWidth={["100%", null, null, null, "60rem"]}
          maxH={[null, null, null, null, "80vh"]}
          minH="18.75rem"
          padding={["1.5rem", null, null, null, "5.625rem"]}
          mx="0.5rem"
          borderRadius="0.5rem"
        >
          <ModalCloseButton />
          <Heading
            size="6"
            w="100%"
            fontWeight="bold"
            textAlign="center"
            mb="4.0069rem"
          >
            {title}
          </Heading>
          <Paragraph overflowY="auto">{modalBody}</Paragraph>
        </ModalContent>
      </Modal>
    </>
  )
}
